import { useEffect, useMemo } from 'react';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import AggregationContent from './AggregationContent/AggregationContent';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getBalanceProductAggregationsByPeriodStart } from 'actions';

const AggregationCard = () => {
  const dispatch: AppDispatch = useDispatch();
  const { productAggregations, currentProduct } = useProductSlicesCombiner();
  //assetId is not displayed on balances page through query parameters
  //but only on product page
  const { assetId } = useParams();

  const productStandard = currentProduct?.standard;
  const dynamicDatas = productStandard?.infoTexts
    ? productStandard?.infoTexts.filter(
        (infoX: any) => infoX.type === 'dynamicData'
      )
    : [];

  const dynamicDataSources: string[] = dynamicDatas.map(
    (infoX: any) => infoX.sourceName
  );
  const productionHistory =
    useMemo(() => {
      if (assetId) {
        const filteredResult = (productAggregations as any[]).filter(
          ({ source }) => !dynamicDataSources.includes(source)
        );
        return filteredResult;
      }
      return;
    }, [productAggregations, assetId]) ?? [];

  const dataHistory = useMemo(() => {
    if (assetId)
      return (productAggregations as any[]).filter(
        ({ source }: { source: string }) =>
          dynamicDataSources.includes(source as string)
      );

    return (currentProduct as any)?.productBalanceAggregations?.filter(
      ({ source }: { source: string }) =>
        dynamicDataSources.includes(source as string)
    );
  }, [productAggregations, currentProduct]);

  useEffect(() => {
    if (!assetId && currentProduct && currentProduct.balancePeriod) {
      const [month, year] = currentProduct.balancePeriod.split('-').map(Number);
      dispatch(
        getBalanceProductAggregationsByPeriodStart(
          currentProduct.productId,
          year,
          month
        )
      );
    }
  }, []);

  if (!productionHistory?.length && !dataHistory?.length) return <></>;

  return (
    <>
      {/* Production History Table */}
      {productionHistory.length > 0 && assetId && (
        <AggregationCardContext.Provider
          value={{ title: 'Production history', data: productionHistory }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}

      {/* Data History Table */}
      {dataHistory.length > 0 && (
        <AggregationCardContext.Provider
          value={{
            title: 'Data history',
            data: dataHistory,
            hideExtraColumns: true,
          }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}
    </>
  );
};

export default AggregationCard;
