import { MenuItem } from '@mui/material';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useParams, useLocation } from 'react-router-dom';

import { CommodityTypes } from '../../../../../../../../../../enums/CommodityTypes';
import ErrorContent from '../../Common/Fields/ErrorContent/ErrorContent';

const ProductTypeAndStandardGroup = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { assetId } = useParams();

  const location = useLocation();
  const isCreate = location.pathname.includes('/products/create');
  const duplicate = assetId && isCreate ? true : false;

  const { productTypes, standards } = useCreateOrUpdateProductSlicesCombiner();
  const availableStandards = [...standards].filter(
    (s) => s.productType?._id === values.productType
  );

  const getOnlyProductTypesHavingStandards = () => {
    return productTypes?.filter((p) => {
      return (
        standards?.filter((s) => {
          return s.productType?._id === p?._id;
        }).length > 0
      );
    });
  };

  const handleProductTypesClick = (e: any) => {
    const newSelectedProductTypeId = e.currentTarget.dataset.value;
    const newAvailableStandards = [...standards].filter(
      (s: any) => s.productType?._id === newSelectedProductTypeId
    );
    setFieldValue(
      'standard',
      Array.isArray(newAvailableStandards) && newAvailableStandards.length > 0
        ? newAvailableStandards[0]?._id
        : ''
    );
  };

  return (
    <GenericGrid isItem xsLength={12} style={{ marginBottom: 15 }}>
      <GenericGrid
        isContainer
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
          <Field
            component={TextField}
            type="text"
            name="productType"
            label={CommodityTypes.upperCase}
            select
            variant="standard"
            margin="normal"
            helperText=""
            style={{ width: 300 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={Boolean(assetId) && !duplicate}
          >
            {getOnlyProductTypesHavingStandards()?.map((s: any) => (
              <MenuItem
                key={s._id}
                value={s._id}
                onClick={handleProductTypesClick}
              >
                {s.name}
              </MenuItem>
            ))}
          </Field>
          <ErrorContent field="productType" />
        </GenericGrid>
        <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
          <Field
            component={TextField}
            type="text"
            name="standard"
            label="Standard"
            select
            variant="standard"
            margin="normal"
            helperText=""
            style={{ width: 300 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={Boolean(assetId) && !duplicate}
          >
            {availableStandards.map((s: any) => (
              <MenuItem key={s._id} value={s._id}>
                {s.name}
              </MenuItem>
            ))}
          </Field>
          <ErrorContent field="standard" />
        </GenericGrid>
      </GenericGrid>
    </GenericGrid>
  );
};

export default ProductTypeAndStandardGroup;
