import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchDataFromS3 } from 'utils/fetch/fetchStaticData';

import organizationIcon from '../../../assets/images/icons/company.png';
import unknownOrganizationLogo from '../../../assets/images/teoLogoBleu.png';
import useOrganizationsSlices from 'components/Balances/Redux/Slices/useOrganizationsSlices';

export const useCompanyLogo = (user: any) => {
  const [companyLogo, setCompanyLogo] = useState(unknownOrganizationLogo);

  useEffect(() => {
    const fetchLogo = async () => {
      const organization = user?.user_metadata?.organization;
      if (organization?.pictureFileName) {
        const filePath = `${organization.pictureFileName}`;
        const fetchedURL = await fetchDataFromS3(filePath);
        setCompanyLogo(fetchedURL || unknownOrganizationLogo);
      }
    };

    fetchLogo();
  }, [user]);

  return { companyLogo };
};

export const useUserLogo = (isAuthenticated: boolean, user: any) => {
  const [userLogo, setUserLogo] = useState<any>(null);
  useEffect(() => {
    if (isAuthenticated && user?.user_metadata?.pictureFileName) {
      const fetchLogo = async () => {
        const userLogoPath = `${user.user_metadata.pictureFileName}`;
        const userLogoURL = await fetchDataFromS3(userLogoPath);
        setUserLogo(userLogoURL);
      };
      fetchLogo();
    }
  }, [isAuthenticated, user]);
  return { userLogo };
};

export const useFormUserLogo = (isAuthenticated: boolean, user: any) => {
  const [userFormLogo, setUserFormLogo] = useState<any>(null);
  const localUserPicture = useSelector(
    (state: RootState) => state.usersReducer.localUserPicture
  );
  useEffect(() => {
    if (isAuthenticated && localUserPicture) {
      const objectUrl = URL.createObjectURL(localUserPicture);
      setUserFormLogo(objectUrl);
    } else if (isAuthenticated && user?.user_metadata?.pictureFileName) {
      const fetchLogo = async () => {
        const userLogoPath = `${user.user_metadata.pictureFileName}`;
        const userLogoURL = await fetchDataFromS3(userLogoPath);
        setUserFormLogo(userLogoURL);
      };
      fetchLogo();
    }
  }, [isAuthenticated, user, localUserPicture]);
  return { userFormLogo };
};

export const useOrganizationLogo = () => {
  const { currentOrganization } = useOrganizationsSlices();
  const [organizationPicture, setOrganizationPicture] = useState<string | null>(
    null
  );
  const [logoLoading, setLogoLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrganizationImage = async () => {
      if (currentOrganization && currentOrganization.pictureFileName) {
        setLogoLoading(true);
        const filePath = currentOrganization.pictureFileName;
        const fetchedURL = await fetchDataFromS3(filePath);
        setOrganizationPicture(fetchedURL || organizationIcon);
        setLogoLoading(false);
      } else {
        setOrganizationPicture(organizationIcon);
        setLogoLoading(false);
      }
    };

    fetchOrganizationImage();
  }, [currentOrganization]);

  return { organizationPicture, logoLoading, setOrganizationPicture };
};
