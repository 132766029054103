/* eslint-disable */

import { enums } from '../enums';
import {
  apiGet,
  apiPost,
  openErrorSnack,
  openInfoSnack,
  openSuccessSnack,
} from './UtilsActions';
import { fetchUpdateBalancesCache } from './AdministrationActions';

export const startTests = (args) => async (dispatch) => {
  try {
    let socketId = localStorage.getItem('socketId');
    let body = {
      ...args,
      socketId,
    };
    const response = await apiPost('/api/administration/tests', body);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    dispatch(
      openInfoSnack('Tests are still processing in background, please wait...')
    );
    dispatch({ type: 'END_TESTS', payload: {} });
  } catch (error) {
    console.log('❌❌❌❌❌❌❌❌❌❌❌');
    console.log(error);
    dispatch(
      openErrorSnack(error.message ? error.message : 'Error starting tests')
    );
    dispatch({ type: 'END_TESTS' });
  }
};

export const removeTestData = (args) => async (dispatch) => {
  try {
    dispatch({ type: 'START_TESTS' });
    let responseDelete = await apiPost('/api/tests/delete');
    let resDelete = await responseDelete.json();
    console.log('✅✅✅✅✅✅✅✅✅✅✅ resDelete : ', resDelete);
    dispatch({ type: 'END_TESTS' });
  } catch (error) {
    console.log('❌❌❌❌❌❌❌❌❌❌❌');
    console.log(error);
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'END_TESTS' });
  }
};

export const resetLogs = () => async (dispatch) => {
  try {
    dispatch({ type: 'START_TESTS' });
    if (process.env.REACT_APP_NODE_ENV == enums.EnvironmentTypes.PRODUCTION)
      throw new Error('This action is not available in your environment.');

    let responseResetLog = await apiPost('/api/tests/reset-logs');
    let resResetLog = await responseResetLog.json();
    console.log('✅✅✅✅✅✅✅✅✅✅✅ resResetLog : ', resResetLog);
    await fetchUpdateBalancesCache();
    dispatch({ type: 'END_TESTS' });
  } catch (error) {
    console.log('❌❌❌❌❌❌❌❌❌❌❌');
    console.log(error);
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'END_TESTS' });
  }
};

export const migrateToRights = () => async (dispatch) => {
  try {
    dispatch({ type: 'START_TESTS' });

    if (
      confirm(
        'Are you sure you want to save migrate rights in the database (you are in the  ' +
          process.env.NODE_ENV +
          ' environment ?'
      )
    ) {
      //Migrate;
      let responseMigrationRights = await apiPost(
        '/api/rights/convert-features-to-rights',
        {}
      );
      let resMigrationRights = await responseMigrationRights.json();
      console.log(
        '✅✅✅✅✅✅✅✅✅✅✅ resMigrationRights : ',
        resMigrationRights
      );
      dispatch(
        openSuccessSnack(
          'Rights script passed : ' + JSON.stringify(resMigrationRights)
        )
      );
      dispatch({ type: 'END_TESTS' });
    } else {
      // Do nothing!
      dispatch(openInfoSnack('Rights script cancelled'));
      dispatch({ type: 'END_TESTS' });
    }
  } catch (error) {
    console.log('❌❌❌❌❌❌❌❌❌❌❌');
    console.log(error);
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'END_TESTS' });
  }
};

export const sendNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: 'START_TESTS' });

    if (
      confirm(
        'Are you sure you want to send notifications for reference documents (you are in the  ' +
          process.env.NODE_ENV +
          ' environment ?'
      )
    ) {
      let responseRefNotifications = await apiPost(
        '/api/files/send-expired-reference-notifications',
        {}
      );
      let resRefNotifications = await responseRefNotifications.json();
      console.log(
        '✅✅✅✅✅✅✅✅✅✅✅ resRefNotifications : ',
        resRefNotifications
      );
      dispatch(
        openSuccessSnack(
          'Notifications sent : ' + JSON.stringify(resRefNotifications)
        )
      );
      dispatch({ type: 'END_TESTS' });
    } else {
      // Do nothing!
      dispatch(openInfoSnack('Send notifications cancelled'));
      dispatch({ type: 'END_TESTS' });
    }
  } catch (error) {
    console.log('❌❌❌❌❌❌❌❌❌❌❌');
    console.log(error);
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'END_TESTS' });
  }
};
