/* eslint-disable */

import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import moment from 'moment';
import { enums } from '../enums';

import { checkAuthentication } from './AuthActions';
import { SnackbarCloseButton } from 'components/commons/Snackbar/SnackbarCloseButton';
import CircularJSON from 'circular-json';
import { convertVolumeFromBlockchain } from 'components/Balances/utils/table/helpers';
import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';
import useBalancesChildComponentsSlices from '../components/Balances/Redux/CustomHooks/useBalancesChildComponentsSlices';
import {
  getBalanceOpenQuantity,
  getBalanceRetiredQuantity,
  getBalanceListAfterOperation,
} from '../actions/BalancesActions';

export const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
});

export const formIsBeingFilledOut = () => (dispatch) => {
  dispatch({ type: 'FORM_IS_BEING_FILLED_OUT' });
};

export const apiGet = async (uri) => {
  let options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  const encodedUri = encodeURI(process.env.REACT_APP_API_URL + uri);
  let response = await fetch(encodedUri, options);
  return response;
};

export async function fetchWithTimeout(resource, options) {
  const { timeout = 800000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export const apiPost = async (uri, bodyInJson) => {
  let body = CircularJSON.stringify(bodyInJson);

  let options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body,
  };

  let response = await fetch(process.env.REACT_APP_API_URL + uri, options);
  return response;
};

export const apiPut = async (uri, bodyInJson) => {
  let body = JSON.stringify(bodyInJson);

  let options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body,
  };

  let response = await fetch(process.env.REACT_APP_API_URL + uri, options);

  return response;
};

export const apiDelete = async (uri, bodyInJson) => {
  let body = JSON.stringify(bodyInJson);

  let options = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body,
  };

  let response = await fetch(process.env.REACT_APP_API_URL + uri, options);
  return response;
};

export const setOzOrGramsQuantityUnit = (unit) => (dispatch) => {
  dispatch({ type: 'SET_OZ_OR_GRAMS_QUANTITY_UNIT', payload: unit });
};

export const openSnack = (message, options) => (dispatch) => {
  let snackBarKey = new Date().getTime() + Math.random();
  dispatch(
    enqueueSnackbar({
      message,
      options: { ...options, key: snackBarKey },
    })
  );
};

export const openInfoSnack = (message, options) => (dispatch) => {
  dispatch(
    openSnack(message, {
      persist: options?.persist ? options?.persist : false,
      action: options?.action ? options?.action : null,
      variant: 'info',
    })
  );
};

export const openSuccessSnack = (message, options) => (dispatch) => {
  dispatch(openSnack(message, { autoHideDuration: 2000, variant: 'success' }));
};

export const openErrorSnack = (message, options) => (dispatch) => {
  dispatch(
    openSnack(message, {
      persist: true,
      action: SnackbarCloseButton,
      variant: 'error',
    })
  );
};

export const checkCookieConsent = () => (dispatch) => {
  let cookieConsent = Cookies.get('CookieConsent');

  if (cookieConsent === true || cookieConsent === 'true')
    dispatch({ type: 'ACCEPT_COOKIES' });
  if (cookieConsent === false || cookieConsent === 'false') {
    Cookies.remove('CookieConsent');
  }
};

export const acceptCookies = () => (dispatch) => {
  dispatch({ type: 'ACCEPT_COOKIES' });
  dispatch(openSuccessSnack('Cookies accepted'));
};

export const declineCookies = () => (dispatch) => {
  dispatch({ type: 'DECLINE_COOKIES' });
  dispatch(openErrorSnack("Cookies declined, you can't use Attributes"));
  setTimeout(() => {
    Cookies.remove('CookieConsent');
  }, 1000);
};

export const activateSocket = (navigate, location) => (dispatch, getState) => {
  socket.emit('app', 'app opened ' + process.env.REACT_APP_API_URL);

  let connectionOk;
  socket.on('connect', function () {
    if (connectionOk === false) {
      dispatch(openSuccessSnack('Connection ok'));
      dispatch(checkAuthentication(navigate, location, undefined));
    }
    localStorage.setItem('socketId', socket.id);
    connectionOk = true;
    dispatch({ type: 'IS_CONNECTED' });
  });

  socket.on('connect_error', (error) => {
    connectionOk = false;
    dispatch({ type: 'IS_DISCONNECTED' });
  });

  socket.on('disconnect', () => {
    connectionOk = false;
    //dispatch(openErrorSnack("Server can't be reached, connection lost"));
    dispatch({ type: 'IS_DISCONNECTED' });
  });

  socket.on('error', () => {
    connectionOk = false;
    dispatch(openErrorSnack('Socket error'));
    dispatch({ type: 'IS_DISCONNECTED' });
  });
  socket.on('close', () => {
    connectionOk = false;
    dispatch(openErrorSnack('Socket close'));
    dispatch({ type: 'IS_DISCONNECTED' });
  });

  //transferCertificateCreatePendingTransactionDBStart
  //transferCertificateCreatePendingTransactionDBSuccess
  //transferCertificateCreatePendingTransactionDBFail
  socket.on('transferCertificateCreatePendingTransactionDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateCreatePendingTransactionDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateCreatePendingTransactionDBSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateCreatePendingTransactionDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateCreatePendingTransactionDBFail', (data) => {
    dispatch(openErrorSnack('Creating pending transaction in database failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;

    console.error('Failed creating pending transaction in DB data', data);

    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateCreatePendingTransactionDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'transferCertificateCreateTransactionBC',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdatePendingTransactionDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //transferCertificateCreateTransactionBCStart
  //transferCertificateCreateTransactionBCSuccess
  //transferCertificateCreateTransactionBCFail
  socket.on('transferCertificateCreateTransactionBCStart', (data) => {
    let to = data.pendingTransaction.to.name;
    let productUnit = data.pendingTransaction.origin.unit;
    let productVolume = convertVolumeFromBlockchain(
      data.pendingTransaction.origin.unit,
      data.pendingTransaction.volume
    );
    productVolume = data.pendingTransaction.volume;
    dispatch(
      openInfoSnack(
        'Processing transfer of ' +
          productVolume +
          ' ' +
          productUnit +
          ' to ' +
          to
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'transferCertificateCreateTransactionBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateCreateTransactionBCSuccess', (data) => {
    let to = data.pendingTransaction.to.name;
    let productUnit = data.pendingTransaction.origin.unit;
    let productVolume = data.pendingTransaction.volume;
    dispatch(
      openSuccessSnack(
        'Blockchain transfer transaction of ' +
          productVolume +
          ' ' +
          productUnit +
          ' to ' +
          to +
          ' succeeded'
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'transferCertificateCreateTransactionBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateCreateTransactionBCFail', (data) => {
    dispatch(openErrorSnack('Creating transaction in blockchain failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;

    console.error('Failed creating transaction in BC', data);
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'transferCertificateCreateTransactionBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdatePendingTransactionDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'transferCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //transferCertificateUpdatePendingTransactionDBStart
  //transferCertificateUpdatePendingTransactionDBSuccess
  //transferCertificateUpdatePendingTransactionDBFail
  socket.on('transferCertificateUpdatePendingTransactionDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdatePendingTransactionDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateUpdatePendingTransactionDBSuccess', (data) => {
    let to = data.transactionWithTxHash.to.name;
    let productUnit = data.transactionWithTxHash.origin.unit;
    let productVolume = data.pendingTransaction.volume;

    let logId = data?.logId;
    if (logId) {
      dispatch(
        openSuccessSnack(
          'Updating pending transaction with txHash in DB succeed'
        )
      );

      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdatePendingTransactionDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateUpdatePendingTransactionDBFail', (data) => {
    dispatch(
      openErrorSnack(
        'Updating pending transaction with txHash in database failed'
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'Updating pending transaction with txHash in DB failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'transferCertificateUpdatePendingTransactionDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'transferCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //transferCertificateUpdateCacheStart
  //transferCertificateUpdateCacheSuccess
  //transferCertificateUpdateCacheFail
  socket.on('transferCertificateUpdateCacheStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'transferCertificateUpdateCache',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateUpdateCacheSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Updating balances cache (1) succeeded'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'transferCertificateUpdateCache',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateUpdateCacheFail', (data) => {
    dispatch(
      openErrorSnack(
        'Updating balances cache failed (transactions are ok, but displayed data could be outdated)'
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'transferCertificateUpdateCacheFail Updating balances cache (1) failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'transferCertificateUpdateCache',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //transferCertificateSendEmailNotificationStart
  //transferCertificateSendEmailNotificationSuccess
  //transferCertificateSendEmailNotificationFail
  socket.on('transferCertificateSendEmailNotificationStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateSendEmailNotificationSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Sending email notifications succeed'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('transferCertificateSendEmailNotificationFail', (data) => {
    dispatch(openErrorSnack('Sending email notifications failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'transferCertificateSendEmailNotificationFail Sending email notifications failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'transferCertificateSendEmailNotification',
            status: 'error',
            message: errorMessage,
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateCreatePendingTransactionDBStart
  //retireCertificateCreatePendingTransactionDBSuccess
  //retireCertificateCreatePendingTransactionDBFail
  socket.on('retireCertificateCreatePendingTransactionDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateCreatePendingTransactionDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateCreatePendingTransactionDBSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateCreatePendingTransactionDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateCreatePendingTransactionDBFail', (data) => {
    dispatch(openErrorSnack('Failed creating pending transaction in DB'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateCreatePendingTransactionDBFail Failed creating pending transaction in DB',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateCreatePendingTransactionDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'retireCertificateCreateTransactionBC',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateUpdatePendingTransactionDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateCreateTransactionBCStart
  //retireCertificateCreateTransactionBCSuccess
  //retireCertificateCreateTransactionBCFail
  socket.on('retireCertificateCreateTransactionBCStart', (data) => {
    let to = data.pendingTransaction.to.name;
    let productUnit = data.pendingTransaction.origin.unit;
    let productVolume = data.pendingTransaction.volume;
    let logId = data?.logId;
    dispatch(
      openInfoSnack(
        'Processing retire of ' +
          productVolume +
          ' ' +
          productUnit +
          ' to ' +
          to
      )
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'retireCertificateCreateTransactionBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateCreateTransactionBCSuccess', (data) => {
    let to = data.pendingTransaction.to.name;
    let productUnit = data.pendingTransaction.origin.unit;
    let productVolume = data.pendingTransaction.volume;
    dispatch(
      openSuccessSnack(
        'Blockchain retire transaction of ' +
          productVolume +
          ' ' +
          productUnit +
          ' to ' +
          to +
          ' succeeded'
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'retireCertificateCreateTransactionBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateCreateTransactionBCFail', (data) => {
    dispatch(openErrorSnack('Failed creating transaction in BC'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateCreateTransactionBCFail Failed creating transaction in BC',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'retireCertificateCreateTransactionBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateUpdatePendingTransactionDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateUpdatePendingTransactionDBStart
  //retireCertificateUpdatePendingTransactionDBSuccess
  //retireCertificateUpdatePendingTransactionDBFail
  socket.on('retireCertificateUpdatePendingTransactionDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateUpdatePendingTransactionDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdatePendingTransactionDBSuccess', (data) => {
    let to = data.transactionWithTxHash.to.name;
    let productUnit = data.transactionWithTxHash.origin.unit;
    let productVolume = data.pendingTransaction.volume;

    let logId = data?.logId;
    if (logId) {
      dispatch(
        openSuccessSnack(
          'Updating pending transaction with txHash in DB succeed'
        )
      );
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateUpdatePendingTransactionDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdatePendingTransactionDBFail', (data) => {
    dispatch(
      openErrorSnack('Updating pending transaction with txHash in DB failed')
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateUpdatePendingTransactionDBFail Updating pending transaction with txHash in DB failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateUpdatePendingTransactionDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateGeneratePdfStart
  //retireCertificateGeneratePdfSuccess
  //retireCertificateGeneratePdfFail
  socket.on('retireCertificateGeneratePdfStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateGeneratePdfSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('PDF Generation after retire succeed'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateGeneratePdfFail', (data) => {
    dispatch(
      openErrorSnack(
        "PDF Generation after retire failed, could be a problem with the organization's logo"
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateGeneratePdfFail PDF Generation after retire failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateGeneratePdf',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateSetPdfUrlInDBStart
  //retireCertificateSetPdfUrlInDBSuccess
  //retireCertificateSetPdfUrlInDBFail
  socket.on('retireCertificateSetPdfUrlInDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateSetPdfUrlInDBSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Set PDF url in DB after retire succeeded'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateSetPdfUrlInDBFail', (data) => {
    dispatch(openErrorSnack('Set PDF url in DB after retire failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateSetPdfUrlInDBFail Set PDF url in DB after retire failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'retireCertificateSetPdfUrlInDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateUpdateCacheStart
  //retireCertificateUpdateCacheSuccess
  //retireCertificateUpdateCacheFail
  socket.on('retireCertificateUpdateCacheStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheSuccess', (data) => {
    let logId = data?.logId;
    dispatch(openSuccessSnack('Retire certificates succeeded'));
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheFail', (data) => {
    dispatch(
      openErrorSnack(
        'Updating balances cache after retire failed (transactions are ok, but displayed data could be outdated)'
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateUpdateCacheFail Updating balances cache after retire (1) failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCache',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateUpdateCacheBisStart
  //retireCertificateUpdateCacheBisSuccess
  //retireCertificateUpdateCacheBisFail
  socket.on('retireCertificateUpdateCacheBisStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheBisSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Updating balances cache (2) succeed'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheBisFail', (data) => {
    dispatch(
      openErrorSnack(
        'Updating balances cache failed (transactions are ok, but displayed data could be outdated)'
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateUpdateCacheBisFail Updating balances cache after retire (2) failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheBis',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateSendEmailNotificationStart
  //retireCertificateSendEmailNotificationSuccess
  //retireCertificateSendEmailNotificationFail
  socket.on('retireCertificateSendEmailNotificationStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateSendEmailNotificationSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Sending email notifications succeed'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateSendEmailNotificationFail', (data) => {
    dispatch(openErrorSnack('Sending email notifications failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateSendEmailNotificationFail Sending email notifications failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'retireCertificateSendEmailNotification',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //retireCertificateUpdateCacheTerStart
  //retireCertificateUpdateCacheTerSuccess
  //retireCertificateUpdateCacheTerFail
  socket.on('retireCertificateUpdateCacheTerStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheTerSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch(openSuccessSnack('Updating balances cache (3) succeed'));
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('retireCertificateUpdateCacheTerFail', (data) => {
    dispatch(
      openErrorSnack(
        'Updating balances cache failed (transactions are ok, but displayed data could be outdated)'
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'retireCertificateUpdateCacheTerFail Updating balances cache (3) failed',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'retireCertificateUpdateCacheTer',
            status: 'error',
            message: errorMessage,
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('retireFail', (data) => {
    dispatch(openErrorSnack('Retire fail'));
  });

  socket.on('transferFail', (data) => {
    dispatch(openErrorSnack('Transfer failed'));
  });

  socket.on('processingIssueCertificatesInBulk', (data) => {
    dispatch(openInfoSnack('Issue balance for multiple products: processing'));
  });

  socket.on('processingNotificationFutureIssue', (data) => {
    dispatch(openInfoSnack('Notifying organization: processing'));
  });

  socket.on('issueCertificatesInBulk_SuccessPreparingBatchCallData', (data) => {
    dispatch(
      openInfoSnack(
        `Prepared batch CallData for product ${data} in blockchain.`
      )
    );
  });

  socket.on(
    'issueCertificatesInBulk_SuccessBatchIssuance_processingUpdateCache',
    (data) => {
      dispatch(
        openInfoSnack(
          `Batch Issuance success for all products. Processing cache update....`
        )
      );
    }
  );

  socket.on(
    'updateCacheInBulk_SuccessForOneElement_processingNextElement',
    (data) => {
      let message = `Cache update success for product ${data._origin}. Processing issuance for next product....`;
      if (data._lastElementInArray)
        message = `Cache update success for product ${data._origin}.`;
      dispatch(openInfoSnack(message));
    }
  );

  socket.on('issueCertificatesInBulkSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Issuance in batch succeded for all selected products. Process complete.`
      )
    );
  });

  socket.on('notificationFutureIssueSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Notifications sent by email to organization. Process complete.`
      )
    );
  });

  socket.on('IssueCertificatesInBulkFail', (data) => {
    dispatch(openErrorSnack(data));
    console.error('IssueCertificatesInBulkFail', data);
  });

  socket.on('notificationFutureIssueFail', (data) => {
    dispatch(
      openErrorSnack('Failed notification of future issue certificates')
    );
    console.error('notificationFutureIssueFail', data);
  });

  socket.on('processingAddProduct', (data) => {
    dispatch(openInfoSnack('Store new product: processing'));
  });

  //addProductInDBStart
  //addProductInDBSuccess
  //addProductInBCSkipped
  //addProductInDBFail

  socket.on('START_TESTS', (data) => {
    dispatch({ type: 'START_TESTS' });
  });

  socket.on('ADD_TEST_LOG', (data) => {
    if (data) {
      let payload = {
        id: data.id,
        createdAt: data.createdAt,
        text: data.text,
        type: data.type,
        status: data.status,
      };
      if (data.index) {
        payload = {
          ...payload,
          index: data.index,
        };
      }
      if (data.numberOfOperations) {
        payload = {
          ...payload,
          numberOfOperations: data.numberOfOperations,
        };
      }
      if (data.steps) {
        payload = {
          ...payload,

          steps: data.steps,
        };
      }
      dispatch({ type: 'ADD_TEST_LOG', payload: payload });
    }
  });

  socket.on('PROGRESS_TEST_LOG', (data) => {
    if (data) {
      dispatch({
        type: 'PROGRESS_TEST_LOG',
        payload: {
          nbOfExecutedOperations: data.nbOfExecutedOperations,
          nbOfOperationsToExecute: data.nbOfOperationsToExecute,
        },
      });
    }
  });

  socket.on('UPDATE_TEST_LOG', (data) => {
    if (data) {
      let payload = {
        id: data.id,
        text: data.text,
        type: data.type,
        status: data.status,
      };
      if (data.createdAt) {
        payload = {
          ...payload,
          createdAt: data.createdAt,
        };
      }
      if (data.duration) {
        payload = {
          ...payload,
          duration: data.duration,
        };
      }
      dispatch({ type: 'UPDATE_TEST_LOG', payload: payload });
    }
  });

  socket.on('addProductInDBStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            status: 'processing',
            name: 'addProductInDB',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('addProductInDBSuccess', (data) => {
    dispatch(
      openInfoSnack(
        `Success storing product ${data.assetID} in database. Storing in blockchain....`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addProductInDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('addProductInDBFail', (data) => {
    dispatch(openErrorSnack('Storing product in database failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error('addProductInDBFail Failed storing product in DB', data);
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addProductInDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'addProductInBC',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('addProductInBCStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'addProductInBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('addProductInBCSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Success storing product attributes for ${data.assetID} in blockchain. Process complete.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'addProductInBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'success',
        },
      });
    }
  });

  socket.on('addProductInBCFail', (data) => {
    dispatch(openErrorSnack('Storing product attributes in blockchain failed'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'addProductInBCFail Failed storing product attributes in blockchain',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'addProductInBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });
    }
  });

  socket.on('addProductInBCSkipped', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'addProductInBC',
            status: 'skipped',
          },
        },
      });
    }
  });

  //updateProductInDBStart
  //updateProductInDBSuccess
  //updateProductInDBFail
  socket.on('updateProductInDBStart', (data) => {
    dispatch(openInfoSnack('Updating an existing product: processing'));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'updateProductInDB',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('updateProductInDBSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Updating product ${data.assetID} in database succeeded.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'updateProductInDB',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('updateProductInDBFail', (data) => {
    dispatch(openErrorSnack(`Updating product in database failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error('updateProductInDBFail Failed updating product in DB', data);
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'updateProductInDB',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //updateProductInBCStart
  //updateProductInBCSuccess
  //updateProductInBCSkipped
  //updateProductInBCFail

  socket.on('updateProductInBCStart', (data) => {
    dispatch(
      openInfoSnack(`Updating product ${data.assetID} in the blockchain...`)
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });
  socket.on('updateProductJSONInBCStart', (data) => {
    dispatch(
      openInfoSnack(
        `Updating JSON product ${data.productId} in the blockchain...`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('updateProductInBCSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Complete update of product ${data.productId} in the blockchain.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'success',
        },
      });
    }
  });
  socket.on('updateProductJSONInBCSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Updating JSON product ${data.productId} in the blockchain succeeded. Process complete.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'success',
        },
      });
    }
  });

  socket.on('updateProductInBCSkipped', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'skipped',
          },
        },
      });
    }
  });

  socket.on('updateProductInBCFail', (data) => {
    dispatch(
      openErrorSnack('Updating product attributes in blockchain failed')
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'updateProductInBCFail Failed updating product attributes in blockchain',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'updateProductInBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //issueCertificate_issueInBCStart
  //issueCertificate_issueInBCSuccess
  //issueCertificate_issueInBCFail

  socket.on('issueCertificate_issueInBCStart', (data) => {
    dispatch(openSuccessSnack(`Start issue certificate in blockchain.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });
  socket.on('issueCertificateBatch_issueInBCStart', (data) => {
    dispatch(openSuccessSnack(`Start issue certificate batch in blockchain.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_issueInBCSuccess', (data) => {
    dispatch(openSuccessSnack(`Issue certificate in blockchain succeeded.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificateBatch_issueInBCSuccess', (data) => {
    dispatch(
      openSuccessSnack(`Issue certificate Batch in blockchain succeeded.`)
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_issueInBCFail', (data) => {
    dispatch(openErrorSnack(`Issue certificate in blockchain failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificate_issueInBCFail Failed issuing certificate in blockchain',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheCurrentUserOrganizationBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheIssuerBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('issueCertificateBatch_issueInBCFail', (data) => {
    dispatch(openErrorSnack(`Issue certificate batch in blockchain failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificateBatch_issueInBCFail Failed issuing certificate in blockchain',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'issueCertificateInBC',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheCurrentUserOrganizationBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheIssuerBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //decacheCurrentUserOrganizationBalance after issue certificate in blockchain
  socket.on(
    'issueCertificate_decacheCurrentUserOrganizationBalanceStart',
    (data) => {
      let logId = data?.logId;
      if (logId) {
        let now = moment().toDate();
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'cache',
              name: 'decacheCurrentUserOrganizationBalance',
              status: 'processing',
              startedAt: moment().toDate(),
            },
          },
        });
      }
    }
  );

  socket.on(
    'issueCertificate_decacheCurrentUserOrganizationBalanceSuccess',
    (data) => {
      dispatch(
        openSuccessSnack(
          `Decaching current user's organization's balance succeeded. Storing transaction in db....`
        )
      );

      let logId = data?.logId;
      if (logId) {
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'cache',
              name: 'decacheCurrentUserOrganizationBalance',
              status: 'success',
              finishedAt: moment().toDate(),
            },
          },
        });
      }
    }
  );

  socket.on(
    'issueCertificate_decacheCurrentUserOrganizationBalanceFail',
    (data) => {
      dispatch(
        openErrorSnack(`Decaching current user's organization's balance failed`)
      );

      let errorMessage = data?.message;
      let logId = data?.logId;
      console.error(
        "issueCertificate_decacheCurrentUserOrganizationBalanceFail Failed decaching current user's organization's balance",
        data
      );
      if (logId) {
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'cache',
              name: 'decacheCurrentUserOrganizationBalance',
              status: 'error',
              message: errorMessage,
            },
          },
        });
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'db',
              name: 'createTransactionInDb',
              status: 'aborted',
            },
          },
        });
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'cache',
              name: 'decacheIssuerBalance',
              status: 'aborted',
            },
          },
        });
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'email',
              name: 'sendNotificationEmail',
              status: 'aborted',
            },
          },
        });
        dispatch({
          type: 'UPDATE_TEST_LOG_STEP',
          payload: {
            id: logId,
            stepToUpdate: {
              type: 'cache',
              name: 'issueCertificateUpdateCache',
              status: 'aborted',
            },
          },
        });

        dispatch({
          type: 'UPDATE_TEST_LOG',
          payload: {
            id: logId,
            status: 'error',
          },
        });
      }
    }
  );

  // issueCertificate_createTransactionInDbStart
  // issueCertificate_createTransactionInDbSuccess
  // issueCertificate_createTransactionInDbFail
  socket.on('issueCertificate_createTransactionInDbStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificateBatch_createTransactionInDbStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_createTransactionInDbSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Storing issue certificate transaction in database succeeded.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificateBatch_createTransactionInDbSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Storing issue certificate batch transactions in database succeeded.`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_createTransactionInDbFail', (data) => {
    dispatch(
      openErrorSnack(
        `Storing issue certificate transaction in database failed.`
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificate_createTransactionInDbFail Failed storing issue certificate transaction in DB',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'decacheIssuerBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('issueCertificateBatch_createTransactionInDbFail', (data) => {
    dispatch(
      openErrorSnack(
        `Storing issue certificate batch transactions in database failed.`
      )
    );

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificateBatch_createTransactionInDbFail Failed storing issue certificate batch transactions in DB',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createTransactionInDb',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'decacheIssuerBalance',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  // issueCertificate_decacheIssuerBalanceStart
  // issueCertificate_decacheIssuerBalanceSuccess
  // issueCertificate_decacheIssuerBalanceFail
  socket.on('issueCertificate_decacheIssuerBalanceStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheIssuerBalance',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_decacheIssuerBalanceSuccess', (data) => {
    dispatch(
      openSuccessSnack(
        `Decaching issuer balance succeeded. Sending email notification....`
      )
    );

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheIssuerBalance',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('decacheBalances_success', () => {
    dispatch(openSuccessSnack('Decache balances updated'));
  });

  socket.on('decacheBalances_fail', () => {
    dispatch(openErrorSnack('Decache balances failed'));
  });

  socket.on('issueCertificate_decacheIssuerBalanceFail', (data) => {
    dispatch(openErrorSnack(`Decaching issuer balance failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificate_decacheIssuerBalanceFail Failed decaching issuer balance',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'decacheIssuerBalance',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  // issueCertificate_sendNotificationEmailStart
  // issueCertificate_sendNotificationEmailSuccess
  // issueCertificate_sendNotificationEmailFail
  socket.on('issueCertificate_sendNotificationEmailStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificateBatch_sendNotificationEmailStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_sendNotificationEmailSuccess', (data) => {
    dispatch(openSuccessSnack(`Sending email notification succeeded.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificateBatch_sendNotificationEmailSuccess', (data) => {
    dispatch(openSuccessSnack(`Sending email notification succeeded.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_sendNotificationEmailFail', (data) => {
    dispatch(openErrorSnack(`Sending email notification failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificate_sendNotificationEmailFail FFailed sending email notification',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('issueCertificateBatch_sendNotificationEmailFail', (data) => {
    dispatch(openErrorSnack(`Sending email notification failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificateBatch_sendNotificationEmailFail Failed sending email notification',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'email',
            name: 'sendNotificationEmail',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  // issueCertificate_decacheBalanceStart
  // issueCertificate_decacheBalanceSuccess
  // issueCertificate_decacheBalanceFail
  socket.on('issueCertificate_decacheBalanceStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('issueCertificate_decacheBalanceSuccess', (data) => {
    dispatch(openSuccessSnack(`Decaching issuer balance succeeded.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'success',
        },
      });
    }
  });

  socket.on('issueCertificate_decacheBalanceFail', (data) => {
    dispatch(openErrorSnack(`Decaching issuer balance failed.`));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'issueCertificate_decacheBalanceFail Failed decaching issuer balance',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'cache',
            name: 'issueCertificateUpdateCache',
            status: 'error',
            message: errorMessage,
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //getDataHashArray

  socket.on('uploadDataset_getDataHashArrayBCStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc_get',
            name: 'getDataHashArray',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_getDataHashArrayBCSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc_get',
            name: 'getDataHashArray',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_getDataHashArrayBCFail', (data) => {
    console.log('Received socket on uploadDataset_getDataHashArrayBCFail');

    dispatch(openErrorSnack('Failed getting datahash array from database'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_getDataHashArrayBCFail Failed getting datahash array',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc_get',
            name: 'getDataHashArray',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'getTxHashFromDataHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'storeDataHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //getTxHashFromDataHash
  socket.on('uploadDataset_getTxHashFromDataHashDbStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'getTxHashFromDataHash',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }

    //In this case, storing data hash in BC and adding it in DB will be skipped
    dispatch({
      type: 'UPDATE_TEST_LOG_STEP',
      payload: {
        id: logId,
        stepToUpdate: {
          type: 'bc',
          name: 'storeDataHash',
          status: 'skipped',
        },
      },
    });
    dispatch({
      type: 'UPDATE_TEST_LOG_STEP',
      payload: {
        id: logId,
        stepToUpdate: {
          type: 'db',
          name: 'addNewHash',
          status: 'skipped',
        },
      },
    });
  });

  socket.on('uploadDataset_getTxHashFromDataHashDbSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'getTxHashFromDataHash',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_getTxHashFromDataHashDbFail', (data) => {
    dispatch(openErrorSnack('Failed getting transaction from datahash'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_getTxHashFromDataHashDbFail Failed getting transaction from datahash',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'getTxHashFromDataHash',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'storeDataHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //storeDataHash
  socket.on('uploadDataset_storeDataHashBCStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'storeDataHash',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_storeDataHashBCSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'storeDataHash',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_storeDataHashBCFail', (data) => {
    console.log('Received socket on uploadDataset_storeDataHashBCFail');

    dispatch(openErrorSnack('Failed storing hash in blockchain'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_storeDataHashBCFail Failed storing hash in blockchain',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'bc',
            name: 'storeDataHash',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //addNewHash

  socket.on('uploadDataset_addNewHashDbStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_addNewHashDbSuccess', (data) => {
    console.log('Received socket on uploadDataset_addNewHashDbSuccess');

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_addNewHashDbFail', (data) => {
    console.log('Received socket on uploadDataset_addNewHashDbFail');

    dispatch(openErrorSnack('Failed adding hash in database'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_addNewHashDbFail Failed adding hash in database',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'addNewHash',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'aborted',
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //createAssetMetering
  socket.on('uploadDataset_createAssetMeteringStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_createAssetMeteringSuccess', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_createAssetMeteringFail', (data) => {
    console.log('Received socket on uploadDataset_createAssetMeteringFail');

    dispatch(openErrorSnack('Failed adding asset metering in database'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_createAssetMeteringFail Failed adding asset metering in database',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'createAssetMetering',
            status: 'error',
            message: errorMessage,
          },
        },
      });
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'aborted',
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  //aggregateAssetMetering
  socket.on('uploadDataset_aggregateAssetMeteringStart', (data) => {
    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'processing',
            startedAt: moment().toDate(),
          },
        },
      });
    }
  });

  socket.on('uploadDataset_aggregateAssetMeteringSuccess', (data) => {
    console.log(
      'Received socket on uploadDataset_aggregateAssetMeteringSuccess'
    );

    dispatch(openSuccessSnack(`Success aggregating asset metering.`));

    let logId = data?.logId;
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'success',
            finishedAt: moment().toDate(),
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'success',
        },
      });
    }
  });

  socket.on('uploadDataset_aggregateAssetMeteringFail', (data) => {
    console.log('Received socket on uploadDataset_aggregateAssetMeteringFail');

    dispatch(openErrorSnack('Failed aggregating asset metering in database'));

    let errorMessage = data?.message;
    let logId = data?.logId;
    console.error(
      'uploadDataset_aggregateAssetMeteringFail Failed aggregating asset metering in database',
      data
    );
    if (logId) {
      dispatch({
        type: 'UPDATE_TEST_LOG_STEP',
        payload: {
          id: logId,
          stepToUpdate: {
            type: 'db',
            name: 'aggregateAssetMetering',
            status: 'error',
            message: errorMessage,
          },
        },
      });

      dispatch({
        type: 'UPDATE_TEST_LOG',
        payload: {
          id: logId,
          status: 'error',
        },
      });
    }
  });

  socket.on('bulkOperations', async ({ variant, message, persist, data }) => {
    let snackBarKey = new Date().getTime() + Math.random();
    switch (variant) {
      case 'success':
        dispatch(openSuccessSnack(message));
        break;
      case 'error':
        dispatch(openErrorSnack(message));
        break;
      case 'info':
        dispatch(openInfoSnack(message));
        break;
      default:
        dispatch(
          enqueueSnackbar({
            message,
            options: {
              key: snackBarKey,
              variant: variant,
              persist,
              action: persist ? SnackbarCloseButton : null,
            },
          })
        );
        break;
    }

    if (data) {
      const state = getState();
      const { allBalances: balances } = state.balancesReducer;
      const { user, isAuthenticated } = state.loginReducer;
      const userIsSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
      const operations = data.operations;
      let updatedBalances = [...balances];

      dispatch({ type: 'GET_BALANCES_START' });
      for (const operation of operations) {
        const period = operation.period;
        const year = period.split('-')[0];
        const month = period.split('-')[1];
        if (userIsSuperAdmin) {
          //add beneficiary balance to balances list
          let foundRecipientBalance = balances.find((balance) => {
            return (
              balance._origin.assetID === operation.asset_id &&
              balance._year === year &&
              balance._month === month &&
              balance._from.identifier === operation.to
            );
          });
          if (!foundRecipientBalance) {
            console.log("Adding recipient's balance to balances list");
            //TO Test
            //KO for an issue certificate operation
            //Could be KO for a transfer operation creating a new balance
            // balances.push({
            //   _origin: _origin,
            //   _year: year,
            //   _month: month,
            //   _from: operation.to,
            //   _quantity: 0,
            //   _allocatedQuantity: 0,
            // });
          }
        }
        //update balances list

        //Always update the balance of the organization that has done the operation
        let fromOrganizationUpdatedBalance = await getBalanceOpenQuantity({
          origin: operation.asset_id,
          organizationIdentifier: operation.from,
          year: year,
          month: month,
        });

        if (operation.type === 'transfer') {
          let toOrganizationUpdatedBalance = await getBalanceOpenQuantity({
            origin: operation.asset_id,
            organizationIdentifier: operation.to,
            year: year,
            month: month,
          });
          updatedBalances = getBalanceListAfterOperation({
            balances,
            operationBody: operation,
            fromBalanceQuantity: fromOrganizationUpdatedBalance,
            toBalanceQuantity: toOrganizationUpdatedBalance,
          });
        }

        if (operation.type === 'retire') {
          const balanceWithOrganizationToFind = balances.find((balance) => {
            return balance._from.identifier === operation.from;
          });
          const _from = balanceWithOrganizationToFind?._from;
          let fromOrganizationAllocatedQuantity =
            await getBalanceRetiredQuantity({
              origin: operation.asset_id,
              organizationMongoId: _from?._id,
              year: year,
              month: month,
            });

          updatedBalances = getBalanceListAfterOperation({
            balances,
            operationBody: operation,
            fromBalanceQuantity: fromOrganizationUpdatedBalance,
            fromBalanceAllocatedQuantity: fromOrganizationAllocatedQuantity,
          });
        }
      }

      dispatch({
        type: 'GET_BALANCES_SUCCESS',
        payload: updatedBalances,
      });
      if (operations.length === 1) {
        if (operations[0].type === 'retire') {
          dispatch({ type: 'ALLOCATE_CERTIFICATES_SUCCESS' });
          dispatch({ type: 'CLOSE_ALLOCATE_CERTIFICATES_FORM' });
        }
        if (operations[0].type === 'transfer') {
          dispatch({ type: 'TRANSFERING_CERTIFICATES_SUCCESS' });
          dispatch({ type: 'CLOSE_TRANSFER_CERTIFICATES_FORM' });
        }
      }
    }
  });
};

export const deactivateSocket = () => (dispatch) => {
  localStorage.removeItem('socketId');
  socket.emit(
    'administration',
    'administration closed ' + process.env.REACT_APP_API_URL
  );
};

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key,
    },
  };
};

export const authorizedExtensions =
  'image/jpeg, image/png, image/jpeg, image/jfif, image/pjpeg, image/pjp';

export const authorizedExtensionMessage =
  'Authorized files extensions(jpeg, png, jfif, pjpeg, pjp';

export const closeSnackbar = (key) => {
  return {
    type: CLOSE_SNACKBAR,
    payload: {
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    },
  };
};

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  payload: {
    key,
  },
});
