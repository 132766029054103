import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import ErrorContent from '../../Common/Fields/ErrorContent/ErrorContent';

const ProductInfoGroup = () => {
  const { t } = useTranslation();
  const { currentProduct, isAuthenticated, user } =
    useCreateOrUpdateProductSlicesCombiner();
  const { assetId } = useParams();

  const location = useLocation();
  const isCreate = location.pathname.includes('/products/create');
  const duplicate = assetId && isCreate ? true : false;

  const displayPlatinum = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

  return (
    <GenericGrid isItem xsLength={12}>
      <GenericGrid
        isContainer
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
          <Field
            style={{ width: 300 }}
            component={TextField}
            label={displayPlatinum ? t('Product name') : t('Displayed name')}
            name="displayedName"
            type="text"
          />
          <ErrorContent field="displayedName" />
        </GenericGrid>
        {checkUserIsSuperAdmin(isAuthenticated, user) &&
          currentProduct &&
          currentProduct.assetID &&
          currentProduct.assetID !== currentProduct.productId &&
          !duplicate && (
            <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
              <Field
                style={{ width: 300 }}
                component={TextField}
                label={t('Asset Id (blockchain reference)')}
                name="assetID"
                type="text"
                disabled={true}
              />
            </GenericGrid>
          )}
        {assetId && !duplicate && (
          <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
            <Field
              style={{ width: 300 }}
              component={TextField}
              label={t('Product ID')}
              name="productId"
              type="text"
              disabled={true}
            />
          </GenericGrid>
        )}
      </GenericGrid>
    </GenericGrid>
  );
};

export default ProductInfoGroup;
