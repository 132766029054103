import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';

import { apiGet, openErrorSnack } from './UtilsActions';

export const getOperationsStart =
  (page = DEFAULT_PAGE_NUMBER, pageSize = DEFAULT_PAGE_SIZE) =>
  async (dispatch: any) => {
    dispatch({ type: 'GET_OPERATIONS_START' });
    const response = await apiGet(
      `/api/operations?page=${page}&limit=${pageSize}`
    );
    if (response.status >= 200 && response.status <= 299) {
      const result = await response.json();
      dispatch({ type: 'GET_OPERATIONS_SUCCESS', payload: result.operations });
      dispatch({
        type: 'GET_OPERATIONS_TOTAL_COUNT_SUCCESS',
        payload: result.totalCount,
      });
    } else {
      dispatch({ type: 'GET_OPERATIONS_FAIL' });
      // Handle errors
      const jsonError = await response.json();
      const errorMessage = jsonError.message
        ? jsonError.message
        : 'An error occured, please try later or reload the page';
      dispatch(openErrorSnack(errorMessage));
      throw new Error(errorMessage);
    }
  };

export const getOperationStart =
  (operationId: string) => async (dispatch: any) => {
    dispatch({ type: 'GET_OPERATION_START' });
    const response = await apiGet(`/api/operations/${operationId}`);
    if (response.status >= 200 && response.status <= 299) {
      const result = await response.json();
      const operationSteps = result.operation.steps
        ? result.operation.steps.map((step: any, index: number) => {
            return { ...step, id: index };
          })
        : [];
      const operation = { ...result.operation, steps: operationSteps };
      dispatch({ type: 'GET_OPERATION_SUCCESS', payload: operation });
    } else {
      dispatch({ type: 'GET_OPERATION_FAIL' });
      // Handle errors
      const jsonError = await response.json();
      const errorMessage = jsonError.message
        ? jsonError.message
        : 'An error occured, please try later or reload the page';
      dispatch(openErrorSnack(errorMessage));
      throw new Error(errorMessage);
    }
  };
