import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Fab } from '@mui/material';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useNavigate } from 'react-router';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

const DuplicateButton = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, currentProduct } = useProductSlicesCombiner();

  if (!checkUserIsSuperAdmin(isAuthenticated, user)) return <></>;
  return (
    <Fab
      data-testid="duplicate-btn-id"
      color="primary"
      aria-label="add"
      style={{
        margin: 0,
        bottom: 'auto',
        right: 128,
        top: 85,
        left: 'auto',
        position: 'fixed',
      }}
      onClick={() => {
        currentProduct && navigate('/products/create/' + currentProduct._id);
      }}
    >
      <ContentCopyIcon />
    </Fab>
  );
};

export default DuplicateButton;
