import { AdministrationActionTypes } from './AdministrationActionTypes';
import { AdministrationOrganization } from './AdministrationOrganization';
import { ApiGatewayStages } from './ApiGatewayStages';
import { AssetType } from './AssetType';
import { BlockchainExplorerUrl } from './BlockchainExplorer';
import { CertificateStatus } from './CertificateStatus';
import { CommodityTypes } from './CommodityTypes';
import { DemoSources } from './DemoSources';
import { EnvironmentTypes } from './EnvironmentTypes';
import { InfoTypes } from './InfoTypes';
import { OrganizationTypes } from './OrganizationTypes';
import { SmartContractVersion } from './SmartContractVersion';
import { StandardTypes } from './StandardTypes';
import { Units } from './Units';
import { UserRole } from './UserRole';

export const enums = {
  AssetType,
  ApiGatewayStages,
  UserRole,
  CertificateStatus,
  BlockchainExplorerUrl,
  SmartContractVersion,
  OrganizationTypes,
  AdministrationActionTypes,
  DemoSources,
  AdministrationOrganization,
  EnvironmentTypes,
  Units,
  StandardTypes,
  InfoTypes,
  CommodityTypes,
};
