import React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { displayComment } from 'actions';
import ArchiveCommentGroup from 'components/Product/Form/GasProductForm/CustomComponents/Card/ProductDetails/CardContent/FieldGroups/ArchiveCommentGroup/ArchiveCommentGroup';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

let ProductFormButtons = (properties) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentProduct,
    loading,
    submitForm,
    assetId,
    restoreProductStart,
    duplicate,
  } = properties;

  return (
    <>
      <div style={{ margin: 20, float: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !currentProduct.active}
          onClick={submitForm}
        >
          {assetId && !duplicate ? 'Update product' : 'Create product'}
        </Button>
        {loading && <CircularProgress size={24} />}{' '}
        {assetId && !duplicate && currentProduct && !currentProduct.active && (
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() =>
              dispatch(restoreProductStart(currentProduct, navigate))
            }
          >
            Restore asset
          </Button>
        )}
        {assetId && !duplicate && currentProduct && currentProduct.active && (
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() => dispatch(displayComment(currentProduct, navigate))}
          >
            Archive product
          </Button>
        )}
        {currentProduct.showCommentInput && (
          <ArchiveCommentGroup
            open={currentProduct.showCommentInput}
            currentProduct={currentProduct}
          ></ArchiveCommentGroup>
        )}
      </div>
    </>
  );
};

export default connect()(ProductFormButtons);
