import React from 'react';
import { Grid } from '@mui/material';
import GenericFieldLabel from 'components/GenericComponents/Grid/Field/FieldLabel/GenericFieldLabel';
import GenericFieldValue from 'components/GenericComponents/Grid/Field/FieldValue/GenericFieldValue';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  prepareDynamicDataWithFilterOption,
  groupBySection,
} from 'components/Product/utils/utils';
import { DefaultProductType } from 'reducers/productsReducer/types';

export type SummaryDynamicContentPropsType = {
  removeHiddenItems?: boolean;
};

const SummaryDynamicContent = (
  props: SummaryDynamicContentPropsType
): JSX.Element => {
  const { currentProduct } = useProductSlicesCombiner();
  const { infoXArray } = prepareDynamicDataWithFilterOption(
    currentProduct as DefaultProductType
  );

  if (!currentProduct) return <></>;
  const groupedData = groupBySection(
    infoXArray,
    currentProduct.displayDynamicData
  );

  return (
    <>
      {groupedData.map((group, groupIndex) => {
        return (
          <React.Fragment key={`group${groupIndex}`}>
            {group.name && (
              <div
                style={{
                  width: '100%',
                  marginTop: 40,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 16,
                  marginRight: 16,
                }}
              >
                <h3>{group.name}</h3>
              </div>
            )}
            {group.data.map((infoX, infoXIndex) => {
              const { label, key, desc, isHidden } = infoX;
              const infoTextItem = currentProduct?.standardValues?.find(
                (sv: any) => sv.key === key
              );
              if (isHidden && props?.removeHiddenItems) return null;

              let val = infoTextItem?.value;
              let tooltipLabel = label !== desc ? desc : '';

              if (infoX.type === 'dynamicData') {
                val = desc;
                tooltipLabel = 'Dynamic data';
              }
              let valAsArray = val;
              if (typeof valAsArray === 'number') valAsArray = [val];
              else if (!Array.isArray(valAsArray)) valAsArray = val?.split('|');
              if (!valAsArray) return null;
              if (infoX.type === 'section') {
                return (
                  <div
                    key={`infoX${infoXIndex}`}
                    style={{
                      width: '100%',
                      marginTop: 40,
                      marginBottom: 0,
                      paddingBottom: 0,
                      marginLeft: 16,
                      marginRight: 16,
                    }}
                  >
                    <h3>{infoX.label}</h3>
                  </div>
                );
              }

              return (
                <Grid item xs={12} md={6} lg={3} key={`infoX${infoXIndex}`}>
                  <GenericFieldLabel
                    label={label}
                    tooltipLabel={tooltipLabel}
                  />
                  {valAsArray.map((item: any, index: number) => (
                    <GenericFieldValue field={item} index={index} key={index} />
                  ))}
                </Grid>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SummaryDynamicContent;
